<template>
    <!--      Start  Form -->
    <v-dialog
     v-if="entity"
      v-model="open"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
        >
          <v-btn
            icon
            :loading="processing"
            :disabled="processing"
            @click="close"
          >
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
        <v-toolbar-title>{{$t('model.app_constant.app_constant') +' ('+(!editMode ?  $t('base.addNew') : $t('base.edit'))+')'}} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
              <validation-observer
                ref="form"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  @submit.prevent="handleSubmit(onSave)"
                  @reset.prevent="reset"
                >
          <v-container>
            <v-row>

              <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.app_constant.att_name')"
                    rules="max:150"
                  >
                <v-text-field
                  prepend-icon="mdi-pencil"
                  v-model="entity.att_name"
                  :error-messages="errors"
                  :placeholder="$t('model.app_constant.att_name')"
                  :label="$t('model.app_constant.att_name')"
                ></v-text-field>
                  </validation-provider>
              </v-col>

              <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.app_constant.att_value')"
                    rules="max:100"
                  >
                <v-text-field
                  prepend-icon="mdi-pencil"
                  v-model="entity.att_value"
                  :error-messages="errors"
                  :placeholder="$t('model.app_constant.att_value')"
                  :label="$t('model.app_constant.att_value')"
                ></v-text-field>
                  </validation-provider>
              </v-col>

              <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.app_constant.description')"
                    rules="max:255"
                  >
                <v-text-field
                  prepend-icon="mdi-pencil"
                  v-model="entity.description"
                  :error-messages="errors"
                  :placeholder="$t('model.app_constant.description')"
                  :label="$t('model.app_constant.description')"
                ></v-text-field>
                  </validation-provider>
              </v-col>

              <v-col cols="12" sm="6">
                <v-switch
                  v-model="entity.status"
                  :label="entity.status ? $t('base.enable') : $t('base.disable')"
                ></v-switch>
              </v-col>

                <v-col cols="12" class="mt-6" align="center">
                  <v-btn
                    class="mr-4"
                    text
                    @click="close"
                    :disabled="processing"
                  >
                    {{ $t("base.cancel") }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    :disabled="processing"
                  >
                    <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
                  </v-btn>
                </v-col>

                        </v-row>
                    </v-container>
                </form>
              </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";
export default defineComponent({
  props: {
    value: null,
    open: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const entity = reactive(props.value);
    const close = () => {
      emit("close");
    };
    const onSave = () => {
      emit("save", entity);
    };
    return {
      entity,
      close,
      onSave
    };
  }
});
</script>
